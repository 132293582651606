import { BlogItemProps } from "@/const/types";

const compareDates = (a: BlogItemProps, b: BlogItemProps) => {
  if (a.date && b.date && new Date(a.date) > new Date(b.date)) {
    return -1;
  }
  if (a.date && b.date && new Date(a.date) < new Date(b.date)) {
    return 1;
  }
  return 0;
};

export default compareDates;
