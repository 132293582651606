import Link from "next/link";
import { BlogItemProps, Path } from "@/const/types";
import MinRead from "./MinRead";

const StoryPreview = ({ item, path }: { item: BlogItemProps; path: Path }) => {
  return (
    <div className="flex w-full flex-col justify-between gap-3">
      <div className="flex flex-col gap-3">
        {item.image && (
          <Link
            href={`${path}/${item.slug}`}
            className="relative aspect-[63/40] overflow-hidden rounded-base"
          >
            {item.image.src && (
              <img
                src={item.image.src}
                alt={item.image.alt ? item.image.alt : item.title || ""}
                className="absolute inset-0 h-full w-full object-cover grayscale-[80%] duration-700 ease-in-out hover:scale-110 hover:grayscale-0"
              />
            )}
          </Link>
        )}

        {(item.logo || item.tag) && (
          <div className="flex flex-wrap items-center justify-between">
            {item.logo && item.logo.src && (
              <img
                src={item.logo.src}
                alt={item.logo.alt || ""}
                className="h-auto max-h-[30px] w-auto max-w-full"
              />
            )}

            {item.tag && (
              <Link
                href={item.tagURL || ""}
                className="rounded-full border border-neutral2 bg-neutral2 px-3 py-[2px] text-sm duration-300 ease-in-out hover:border-neutral1 hover:bg-transparent"
              >
                {item.tag}
              </Link>
            )}
          </div>
        )}

        <Link
          href={`${path}/${item.slug}`}
          className="cursor-pointer text-lg font-bold hover:underline"
        >
          {item.title}
        </Link>
      </div>
    </div>
  );
};

export default StoryPreview;
