"use client";
import useEmblaCarousel from "embla-carousel-react";
import StoryPreview from "./StoryPreview";
import { BlogItemProps, Path } from "@/const/types";
import compareDates from "@/utils/compareDates";

const CarouselSuccessStories = ({
  items,
  path,
}: {
  items?: BlogItemProps[];
  path: Path;
}) => {
  const [emblaRef] = useEmblaCarousel({
    align: "start",
    breakpoints: {
      "(min-width: 1440px)": { watchDrag: false },
    },
  });
  if (items) {
    return (
      <div
        className="embla mx-auto w-full overflow-hidden laptop:-mx-[10px] laptop:w-[100%+10px]"
        ref={emblaRef}
      >
        <div className="embla__container flex [&>*]:min-w-0 [&>*]:flex-[0_0_80%] [&>*]:pl-[20px] [&>*]:pr-[1px] tablet:[&>*]:flex-[0_0_50%] laptop:[&>*]:flex-[0_0_25%] laptop:[&>*]:px-[10px]">
          {items.sort(compareDates).map((item, i) => (
            <div key={i} className="embla__slide flex">
              <StoryPreview item={item} path={path} />
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default CarouselSuccessStories;
