"use client";

import BaseContainer from "./elements/BaseContainer";
import Link from "next/link";
import { navlinks } from "@/const/navlinks";
import { useState } from "react";

const mainButtonList = [
  {
    text: navlinks.goals.title,
    icon: navlinks.goals.icon,
    alt: navlinks.goals.title,
  },
  {
    text: navlinks.industries.title,
    icon: navlinks.industries.icon,
    alt: navlinks.industries.title,
  },
];

const regularButtonList = [navlinks.goals.items, navlinks.industries.items];

const tabsList = regularButtonList.map(source => (
  <>
    {source.map((btn, i) => (
      <Link
        href={btn.href || ""}
        className="flex min-h-[50px] items-center gap-6 rounded-full bg-white px-6 py-1 text-left font-medium duration-700 ease-in-out hover:cursor-pointer hover:bg-primarygray hover:text-white"
        key={i}
      >
        {btn.icon && <img src={btn.icon} alt={btn.title} />}
        {btn.title}
      </Link>
    ))}
  </>
));

const CTASolutions = () => {
  const [tabVisible, setTabVisible] = useState(0);
  return (
    <div className="my-[120px]">
      <BaseContainer mobileFull={true}>
        <div className="rounded-base bg-primary px-5 py-[60px] laptop:p-20">
          <div className="mx-auto flex max-w-[950px] flex-col items-center gap-10">
            <h2 className="text-center text-[24px] font-bold text-white tablet:text-h2">
              Explore our composable software solutions by:
            </h2>
            <div className="flex flex-wrap justify-center gap-5">
              {mainButtonList.map((btn, i) => (
                <button
                  key={i}
                  onClick={() => setTabVisible(i)}
                  className={`flex min-h-[70px] items-center gap-6 rounded-full px-8 py-1 text-left font-medium duration-300 ease-in-out hover:cursor-pointer hover:bg-primarygray hover:text-white ${i === tabVisible ? "bg-primarygray text-white" : "bg-white text-primaryblack"}`}
                >
                  <img src={btn.icon} alt={`icon for ${btn.text}`} />
                  {btn.text}
                </button>
              ))}
            </div>
            <div className="h-[1px] w-full bg-primarygray" />

            <div className="flex flex-wrap justify-center gap-5">
              {tabsList[tabVisible]}
            </div>
          </div>
        </div>
      </BaseContainer>
    </div>
  );
};

export default CTASolutions;
