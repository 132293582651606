"use client";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

type Props = {
  images: { name: string; filePath: string }[];
};

export function CarouselLogoCloudClients({ images }: Props) {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [
    Autoplay({ delay: 2000 }),
  ]);

  return (
    <div
      className="embla mx-auto w-full max-w-[1560px] overflow-hidden"
      ref={emblaRef}
    >
      <div className="embla__container flex h-[80px] [&>*]:mx-auto [&>*]:flex [&>*]:min-w-0 [&>*]:flex-[0_0_180px] [&>*]:items-center [&>*]:justify-center [&>*]:px-8">
        {images.map(item => (
          <div className="embla__slide" key={item.name}>
            <img
              src={item.filePath}
              alt={item.name}
              className="h-full w-full object-contain object-center"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
